























import Vue from "vue";
export default Vue.extend({
    name: "CUploadInfo",
    props: {
        onlyImage: {
            required: false,
            type: Boolean,
        },
        pdfImageExcel: {
            required: false,
            type: Boolean,
        },
        pdfImage: {
            required: false,
            type: Boolean,
        },
    },
});
