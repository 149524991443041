var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", [
    _c("li", [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_info_1")) + " ")]),
    _c("li", [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_info_4")) + " ")]),
    _vm.onlyImage
      ? _c("li", [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_info_2")) + " ")])
      : _vm._e(),
    _vm.pdfImageExcel
      ? _c("li", [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_info_3")) + " ")])
      : _vm._e(),
    _vm.pdfImage
      ? _c("li", [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_info_5")) + " ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }