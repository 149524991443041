



















































































































































































































































































































































































































import CSelectBillAddress from "@/components/shared/select-bill-address/CSelectBillAddress.vue";
import CSelectShipAddress from "@/components/shared/select-shipping-address/CSelectShippingAddress.vue";
import CUploadInfo from "@/components/shared/upload/CUploadInfo.vue";
import { generateUuid } from "@/helpers/common";
import { transformDataURL } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { QUOTATION_STATUS } from "@/models/enums/quotation.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { initDetailQuotation } from "@/store/resource/quotation.resource";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_TIME, DEFAULT_TIME_FORMAT } from "@constant/date.constant";
import { IOption } from "@interface/common.interface";
import { ContactData, IAddressDataList, ResponseListContactData } from "@interface/contact.interface";
import { ResponseListAccountingCurrency } from "@interface/currency.interface";
import { ResponseUploadData } from "@interface/file.interface";
import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQuotationConsumeItemCreate, RequestQuotationConsumeProductCreate, RequestQuotationCreate, RequestQuotationUpdate, ResponseCreateQuotation, ResponseQuotationConsumeProduct, ResponseQuotationDetail } from "@interface/quotation.interface";
import { ResponseListBranchWarehouse } from "@interface/warehouse.interface";
import { accountingCurrencyService } from "@service/accounting-currency.service";
import { contactServices } from "@service/contact.service";
import { fileServices } from "@service/file.service";
import { masterTypeService } from "@service/master-type.service";
import { quotationService } from "@service/quotation.service";
import { warehouseBranchService } from "@service/warehouse-branch.service";
import moment, { Moment } from "moment";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { ITableRow } from "./QuotationTable.vue";

export default Vue.extend({
  name: "QuotationForm",
  components: {
    CSelectBillAddress,
    CSelectShipAddress,
    CUploadInfo,
    "QuotationTable": () => import(/*webpackPrefetch:true */"./QuotationTable.vue"),
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true*/"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
    CSelectFreight: () => import(/*webpackPrefetch: true*/"@/components/shared/select-freight/CSelectFreight.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true*/"@/components/shared/select-currency/CSelectCurrency.vue"),
    CUpload: () => import(/*webpackPrefetch: true*/"@/components/shared/upload/CUpload.vue"),
    CSelectCustomerCode: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer-code/CSelectCustomerCode.vue"),
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectSalesType: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sales-type/CSelectSalesType.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT_TIME,
      DEFAULT_DATE_FORMAT,
      DEFAULT_TIME_FORMAT,
      formModel: {
        customerName: undefined as string | undefined,
        customerCode: undefined as string | undefined,
        customerId: "",
        customerContact: "",
        customerPoNumber: "",
        customerPoAttachment: null as string | null,
        customerBillAddress: undefined as string | undefined,
        currency: undefined as string | undefined,
        top: undefined as undefined | string,
        customerShippingAddress: undefined as string | undefined,
        etaDate: null as null | Moment,
        etaHour: null as null | Moment,
        freight: undefined as string | undefined,
        orderDate: null as null | Moment,
        branch: undefined as string | undefined,
        salesName: "",
        salesType: undefined as string | undefined,
        expiredDate: null as null | Moment,
        rfqNumber: "",
        status: "",
        notes: "",
        productLines: [] as ITableRow[]
      },
      formRules: {
        customerName: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        customerCode: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        customerBillAddress: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        currency: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        top: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        customerShippingAddress: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        etaDate: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        etaHour: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        freight: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        orderDate: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        branch: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        salesName: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        salesType: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        expiredDate: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
        customerPoNumber: [{ required: true, message: () => this.$t("lbl_validation_required_error") }],
      },
      formProps: {
        customer: {
          name: {
            label: "lbl_customer_name",
            placeholder: "lbl_choose"
          },
          code: {
            label: "lbl_customer_code",
            placeholder: "lbl_choose"
          },
          contact: {
            label: "lbl_customer_contact",
            placeholder: "lbl_type_here"
          },
          poNumber: {
            label: "lbl_po_number",
            placeholder: "lbl_type_here"
          },
          poAttachment: {
            label: "lbl_po_attachment",
          },
        },
        payment: {
          billAddress: {
            label: "lbl_customer_bill_address",
            placeholder: "lbl_choose"
          },
          currency: {
            label: "lbl_currency",
            placeholder: "lbl_choose"
          },
          top: {
            label: "lbl_term_of_payment",
            placeholder: "lbl_choose"
          }
        },
        delivery: {
          shippingAddress: {
            label: "lbl_customer_shipping_address",
            placeholder: "lbl_choose"
          },
          etaDate: {
            label: "lbl_eta_date",
            placeholder: "lbl_choose"
          },
          freight: {
            label: "lbl_freight",
            placeholder: "lbl_choose"
          },
        },
        order: {
          date: {
            label: "lbl_order_date",
            placeholder: "lbl_choose"
          },
          branch: {
            label: "lbl_branch",
            placeholder: "lbl_choose"
          },
          salesName: {
            label: "lbl_sales_person_name",
            placeholder: "lbl_choose"
          },
          salesType: {
            label: "lbl_sales_type",
            placeholder: "lbl_choose"
          },
          expiredDate: {
            label: "lbl_expired_date",
            placeholder: "lbl_choose"
          },
          rfqNumber: {
            label: "lbl_rfq_number",
          },
          status: {
            label: "lbl_status",
          },
          note: {
            label: "lbl_notes",
            placeholder: "lbl_type_here"
          },
        }
      },
      loading: {
        customerName: false,
        top: false,
        branch: false,
        salesType: false,
        submit: false,
        detail: false,
        cancel: false,
      },
      dtOpt: {
        customerName: [] as IOption[],
        customerBillAddress: [] as IAddressDataList[],
        customerShipToAddress: [] as IAddressDataList[],
        top: [] as IOption[],
        branch: [] as IOption[],
        salesType: [] as IOption[],
      },
      dtList: {
        customerName: {} as ResponseListContactData,
        branch: {} as ResponseListBranchWarehouse,
      },
      quotationId: "",
      detailQuotation: {
        status: QUOTATION_STATUS.NEW
      } as ResponseQuotationDetail,
      deletedConsumeId: [] as string[],
      poAttachmentLink: null as string | ArrayBuffer | null,
    };
  },
  computed: {
    ...mapGetters({
      getUsername: "authStore/GET_USERNAME"
    }),
    compProps() {
      return {
        propBranchId: this.formModel.branch,
        propDetailQuotation: this.detailQuotation,
        propCustomerId: this.formModel.customerId
      };
    },
    compEvt() {
      return {
        "on-data-update": this.onDataTableUpdate,
        "on-latest-order": this.onLatestOrderFound,
      };
    },
    isWarehousePicked(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.WAREHOUSE_PICKED;
    },
    isCancelled(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.CANCELLED;
    },
    isQuotationSubmitted(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.QUOTATION_SUBMITTED;
    },
    isNew(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.NEW;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    showExpireDate(): boolean {
      return this.formModel.salesType?.toUpperCase() === "RESERVE";
    },
  },
  watch: {
    getUsername(newValue: string): void {
      this.formModel.salesName = newValue;
    },
  },
  created() {
    this.setDefaultOption();
    if (this.$route.params.id) {
      this.quotationId = this.$route.params.id;
      this.fetchDetailQuotation(this.quotationId);
    }
  },
  beforeDestroy(): void {
    this.setDetailQuotation(initDetailQuotation());
  },
  methods: {
    moment,
    ...mapMutations({
      setDetailQuotation: "quotationStore/SET_DETAIL_QUOTATION",
    }),
    getListCustomer(params: RequestQueryParamsModel): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    getListCurrency(params: RequestQueryParamsModel): Promise<ResponseListAccountingCurrency> {
      return accountingCurrencyService.listOfMasterCurrency(params);
    },
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    getListBranch(params: RequestQueryParamsModel): Promise<ResponseListBranchWarehouse> {
      return warehouseBranchService.listWarehouseBranch(params);
    },
    createQuotation(payload: RequestQuotationCreate): Promise<ResponseCreateQuotation> {
      return quotationService.createQuotation(payload);
    },
    getDetailQuotation(id: string): Promise<ResponseQuotationDetail> {
      return quotationService.getDetailQuotation(id);
    },
    updateQuotation(id: string, payload: RequestQuotationUpdate): Promise<ResponseQuotationDetail> {
      return quotationService.updateQuotation(id, payload);
    },
    onDataTableUpdate(e: {productLines: ITableRow[], deletedRow: string[]}): void {
      this.formModel.productLines = e.productLines;
      this.deletedConsumeId = e.deletedRow;
    },
    handleBack(): void {
      this.$router.push({name: "sales.transactionsales.quotation"});
    },
    onchangeCustomerName({ meta }: { meta: ContactData }): void {
      this.formModel.customerName = meta.fullName;
      this.formModel.customerId = meta.id;
      this.formModel.customerCode = meta.customerNumber;
      this.formModel.customerContact = meta.phoneNumber || "";
      const billAddress = meta.addressDataList.find(x => x.billTo && x.primaryBillTo);
      const shipAddress = meta.addressDataList.find(x => x.shipTo && x.primaryShipTo);
      if (billAddress) {
        this.formModel.customerBillAddress = `${billAddress.address}, ${billAddress.cityDistrict}, ${billAddress.country}, ${billAddress.postalCode}`;
      }
      if (shipAddress) {
        this.formModel.customerShippingAddress = `${shipAddress.address}, ${shipAddress.cityDistrict}, ${shipAddress.country}, ${shipAddress.postalCode}`;
      }
      this.findCustTop(this.formModel.customerId);
      this.fillOptAddress(meta);
    },
    async findCustTop(id: string | undefined): Promise<void> {
      try {
        if (!id) return;
        const { top } = await contactServices.getContactData(id);
        this.formModel.top = top !== null ? top.toString() : undefined;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    /**
     * fill options for bill and
     * shipping address based on
     * customer name
     */
    fillOptAddress(cust: ContactData | undefined): void {
      this.dtOpt.customerBillAddress = cust?.addressDataList|| [];
      this.dtOpt.customerShipToAddress = cust?.addressDataList || [];
    },
    validateTable(): { valid: boolean, message: string } {
      let returnVal = { valid: true, message: "" };
      const { productLines } = this.formModel;
      let index = 0;
      if (!productLines.length) return { valid: false, message: "lbl_product_empty" };
      for (index; index < productLines.length; index++) {
        const x = productLines[index];
        if (!x.consumedProducts.length) {
          returnVal.valid = false;
          returnVal.message = "lbl_consume_product_empty";
          break;
        }
        if (x.qtyOrder <= 0) {
          returnVal.valid = false;
          returnVal.message = "lbl_qty_order_empty";
          break;
        }
        if (x.sellPrice <= 0) {
          returnVal.valid = false;
          returnVal.message = "lbl_sell_price_empty";
          break;
        }
        if (!x.condition) {
          returnVal.valid = false;
          returnVal.message = "lbl_condition_empty";
          break;
        }
        if (!x.invoiceWeight) {
          returnVal.valid = false;
          returnVal.message = "lbl_invoice_weight_empty";
          break;
        }
        if (x.isProcessed && !x.processInfo.length) {
          returnVal.valid = false;
          returnVal.message = "lbl_proccess_info_empty";
          break;
        }
        const noCutting = x.processInfo.find((row) => !row.cutting);
        if (x.isProcessed && noCutting) {
          returnVal.valid = false;
          returnVal.message = "lbl_process_info_no_cutting";
          break;
        }
      }
      return returnVal;
    },
    validateSalePrice(): boolean {
      const prod = this.formModel.productLines.find(x => x.isUnderMaximumBasePrice);
      return !!prod;
    },
    showConfirmSubmission(): void {
      this.$confirm({
        title: () => this.$t("lbl_warning_title").toString(),
        content: () => this.$t("confirm_sell_price_is_under_max_base_price").toString(),
        onOk: () => this.handleSubmission(),
        cancelText: this.$t("lbl_cancel").toString(),
        okText: this.$t("lbl_confirm").toString()
      });
    },
    handleSubmission(): void {
      if (this.quotationId) {
        this.submitUpdate();
      } else {
        this.createNewQuotation();
      }
    },
    handleSubmit(): void {
      const form: any = this.$refs.formQuotation;
      form.validate((valid: boolean) => {
        const res = this.validateTable();
        if (valid && res.valid) {
          if (this.validateSalePrice()) {
            this.showConfirmSubmission();
          } else {
            this.handleSubmission();
          }
        } else {
          this.showNotifValidationError(this.$t(res.message));
        }
      });
    },
    constructRequestCreateConsumedProductLine(x: ITableRow): RequestQuotationConsumeProductCreate[] {
      const lines: RequestQuotationConsumeProductCreate[] = [];
      x.consumedProducts.forEach(y => {
        lines.push({
          basePrice: y.basePrice || 0,
          productId: y.productId || "",
          uomId: y.uomId || "",
        });
      });
      return lines;
    },
    constructRequestCreateConsumedProduct(): RequestQuotationConsumeItemCreate[] {
      const { formModel } = this;
      const lines: RequestQuotationConsumeItemCreate[] = [];
      if (formModel.productLines.length) {
        formModel.productLines.forEach((x, i) => {
          lines.push({
            alias: x.alias,
            condition: x.condition || "",
            discount: x.discount || 0,
            invoiceWeight: x.invoiceWeight || "",
            productConsume: [],
            qtyOrder: x.qtyOrder || 0,
            salesPrice: x.sellPrice || 0,
            warehouseNote: x.warehouseNote || "",
            processInfo: x.processInfo.map(y => ({
              cutting: y.cutting || "",
              image: y.image,
              packQty: y.packQty || "",
              processNote: y.processNote,
              size: y.size,
              sticker: y.sticker || "",
              trayOption: y.trayOption,
              trayType: null,
              vacuumOption: y.vacuumOption,
              vacuumType: null,
            })),
            isProcessed: x.isProcessed,
          });
          lines[i].productConsume = this.constructRequestCreateConsumedProductLine(x);
        });
      }
      return lines;
    },
    constructRequestCreate(): RequestQuotationCreate {
      const { formModel } = this;
      const req: RequestQuotationCreate = {
        branchId: formModel.branch || "",
        consume: [],
        currencyId: formModel.currency || "",
        customerBillToAddress: formModel.customerBillAddress || "",
        customerContact: formModel.customerContact || "",
        customerId: formModel.customerId || "",
        customerPo: formModel.customerPoNumber || "",
        customerPoAttachment: formModel.customerPoAttachment,
        customerShipToAddress: formModel.customerShippingAddress || "",
        etaDate: formModel.etaDate?.format() || "",
        expireDate: formModel.expiredDate?.format() || null,
        freight: formModel.freight || "",
        note: formModel.notes || "",
        orderDate: formModel.orderDate?.format() || "",
        salesName: formModel.salesName || "",
        salesType: formModel.salesType || "",
        top: formModel.top !== null && formModel.top !== undefined ? +formModel.top : 0,
      };
      req.consume = this.constructRequestCreateConsumedProduct();
      return req;
    },
    async createNewQuotation(): Promise<void> {
      try {
        this.loading.submit = true;
        const req: RequestQuotationCreate = this.constructRequestCreate();
        const { id } = await this.createQuotation(req);
        this.showSubmitSuccessMesssage();
        this.$router.replace({name: "sales.transactionsales.quotation.edit", params: { id }});
      } catch (error) {
        this.showErrorMessage("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    setAddressOptions(custId: string): void {
      this.dtOpt.customerBillAddress = [];
      this.dtOpt.customerShipToAddress = [];
      contactServices.getContactData(custId)
      .then(({addressDataList}) => {
        this.dtOpt.customerBillAddress = addressDataList;
        this.dtOpt.customerShipToAddress = addressDataList;
      });
    },
    async fetchDetailQuotation(id: string): Promise<void> {
      try {
        this.loading.detail = true;
        const res = await this.getDetailQuotation(id);
        this.detailQuotation = res;
        this.setDetailQuotation(res);
        this.getAttachment(res.customerPoAttachment);
        this.prefillDocument(res);
        this.setAddressOptions(res.customerId);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.detail = false;
      }
    },
    prefillDocument(data: ResponseQuotationDetail, source?: string): void {
      try {
        let { formModel } = this;
        formModel = {
          customerCode: data.customerCode || "",
          customerName: data.customerName || "",
          customerId: data.customerId || "",
          customerContact: data.customerContact || "",
          customerPoNumber: (source === "latestOrder" ? "" : (data.customerPo || "")),
          customerPoAttachment: data.customerPoAttachment,
          customerBillAddress: data.customerBillToAddress || "",
          currency: data.currencyId || "",
          top: data.top !== null ? data.top + "" : undefined,
          customerShippingAddress: data.customerShipToAddress || "",
          etaDate: (source === "latestOrder" ? null : (data.etaDate ? this.moment(data.etaDate) : null)),
          etaHour: (source === "latestOrder" ? null : (data.etaDate ? this.moment(data.etaDate) : null)),
          freight: data.freight || "",
          orderDate: source === "latestOrder" ? null : data.orderDate ? this.moment(data.orderDate) : null,
          branch: data.branchId || "",
          salesName: data.salesName || "",
          salesType: data.salesType || "",
          expiredDate: data.expireDate ? this.moment(data.expireDate) : null,
          rfqNumber: (source === "latestOrder" ? "" : data.rfqNumber),
          status: (source === "latestOrder" ? "" : data.status),
          notes: data.note || "",
          productLines: [] as ITableRow[]
        };
        formModel.productLines = data.consume.map((x, i) => {
          return {
            processInfo: x.processInfo.map((y) => ({
              ...y,
              trayType: null,
              key: generateUuid(),
            })),
            alias: x.alias,
            id: x.id || "",
            key: x.id,
            no: i,
            qtyOrder: x.qtyOrder || 0,
            sellPrice: x.salesPrice || 0,
            discount: x.discount || 0,
            warehouseNote: x.warehouseNote || "",
            condition: x.condition || "",
            invoiceWeight: x.invoiceWeight || "",
            deletedConsumedProducts: [],
            isUnderMaximumBasePrice: this.findConditionBasePrice(x.salesPrice, x.consumeProduct),
            consumedProducts: x.consumeProduct.map((y) => {
              return {
                id: y.id || "",
                key: y.id,
                productCode: y.productCode || "",
                productId: y.productId || "",
                productName: y.productName || "",
                uomId: y.uomId || "",
                uom: y.uomName || "",
                basePrice: y.basePrice || 0,
                locationRackId: y.locationId || "",
                locationRack: y.locationName || "",
              };
            }),
            headerColProducts: [],
            isProcessed: x.isProcessed,
          };
        });
        this.formModel = {...formModel};
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    findConditionBasePrice(salePrice: number, consume: ResponseQuotationConsumeProduct[]): boolean {
      const prices: number[] = consume.map(x => x.basePrice);
      const max = prices.reduce((a, b) => {
        return Math.max(a, b);
      }, 0);
      return salePrice < max;
    },
    setDefaultOption(): void {
      // this.formModel.branch = ""
      this.formModel.salesName = this.getUsername;
      this.formModel.etaDate = this.moment().set({ hour: 17, minute: 0 });
      this.formModel.etaHour = this.moment().set({ hour: 17, minute: 0 });
    },
    createRequestUpdate(): RequestQuotationUpdate {
      const req: RequestQuotationUpdate = {
        branchId: this.formModel.branch || "",
        consume: [],
        currencyId: this.formModel.currency || "",
        customerBillToAddress: this.formModel.customerBillAddress || "",
        customerContact: this.formModel.customerContact || "",
        customerId: this.formModel.customerId || "",
        customerPo: this.formModel.customerPoNumber || "",
        customerPoAttachment: this.formModel.customerPoAttachment,
        customerShipToAddress: this.formModel.customerShippingAddress || "",
        deletedConsumeId: this.deletedConsumeId,
        etaDate: this.formModel.etaDate?.format() || "",
        expireDate: this.formModel.expiredDate?.format() || "",
        freight: this.formModel.freight || "",
        note: this.formModel.notes || "",
        orderDate: this.formModel.orderDate?.format() || "",
        salesName: this.formModel.salesName || "",
        salesType: this.formModel.salesType || "",
        status: QUOTATION_STATUS.QUOTATION_SUBMITTED,
        top: this.formModel.top !== null && this.formModel.top !== undefined ? +this.formModel.top : 0,
      };
      if (this.formModel.productLines.length) {
        req.consume = this.formModel.productLines.map(x => {
          return {
            alias: x.alias,
            condition: x.condition || "",
            deleteConsumeProductId: x.deletedConsumedProducts || [],
            discount: x.discount || 0,
            secureId: x.id || "",
            invoiceWeight: x.invoiceWeight || "",
            isProcessed: x.isProcessed,
            productConsume: x.consumedProducts.map(y => {
              return {
                basePrice: y.basePrice || 0,
                id: y.id || "",
                productId: y.productId || "",
                uomId: y.uomId || "",
              };
            }),
            qtyOrder: x.qtyOrder || 0,
            salesPrice: x.sellPrice || 0,
            warehouseNote: x.warehouseNote || "",
            processInfo: x.processInfo.map(y => ({
              cutting: y.cutting || "",
              image: y.image,
              packQty: y.packQty || "",
              processNote: y.processNote,
              size: y.size,
              sticker: y.sticker || "",
              trayOption: y.trayOption,
              trayType: null,
              vacuumOption: y.vacuumOption,
              vacuumType: null,
            })),
          };
        });
      }
      return req;
    },
    async submitUpdate(isCancel = false): Promise<void> {
      try {
        this.loading.submit = true;
        const req = this.createRequestUpdate();
        if (isCancel) {
          req.status = QUOTATION_STATUS.CANCELLED;
          this.loading.cancel = true;
        }
        await this.updateQuotation(this.quotationId, req);
        this.$message.success({
          content: this.$t("notif_update_success").toString()
        });
        this.fetchDetailQuotation(this.quotationId);
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loading.submit = false;
        this.loading.cancel = false;
      }
    },
    onLatestOrderFound(e: { order: ResponseQuotationDetail }): void {
      if (e.order.id) {
        this.prefillDocument(e.order, "latestOrder");
      }
    },
    handleCancel(): void {
      this.showConfirmation().then((confirm: boolean) => {
        if (!confirm) return;
        this.submitUpdate(true);
      });
    },
    onUploadDone(res: ResponseUploadData): void {
      this.formModel.customerPoAttachment = res.objectName;
    },
    onUploadRemoved(): void {
      this.formModel.customerPoAttachment = null;
    },
    onChangeOrderDate(param: Moment): void {
      this.formModel.etaDate = param;
      if (this.showExpireDate) {
        const date = this.moment(param);
        this.formModel.expiredDate = date.add(30, "days") || null;
      } else {
        this.formModel.expiredDate = null;
      }
    },
    onChangeSalesType(): void {
      if (this.showExpireDate) {
        this.formModel.expiredDate = null;
        this.formModel.orderDate = null;
      }
    },
    disabledDateExpireDate(current: Moment): boolean {
      const { orderDate } = this.formModel;
      const theday = this.moment(orderDate);
      return current && current < theday.add(30, "days");
    },
    async getAttachment(filename: string | null): Promise<void> {
      try {
        if (!filename) return;
       const blob = await fileServices.getFile(filename);
       this.poAttachmentLink = await transformDataURL(blob);
      } catch (error) {
        this.showNotifError("notif_file_get_fail", { filename });
      }
    },
    onChangeEtaHour(value: Moment): void {
      const { etaDate } = this.formModel;
      if (!etaDate) return;
      this.formModel.etaDate = etaDate.set({ hour: value.hour(), minute: value.minute() });
    },
    disabledDateEtaDate(current: Moment): boolean {
      if (this.formModel.orderDate) {
        return current < this.formModel.orderDate;
      }
      return false;
    },
    onSelectCustomerCode({ meta }: { value: string, meta: ContactData }): void {
      this.formModel.customerId = meta.id;
      this.formModel.customerName = meta.fullName;
      this.formModel.customerContact = meta.phoneNumber || "";
      this.formModel.customerBillAddress = meta.addressDataList.find(x => x.billTo && x.primaryBillTo)?.address || "";
      this.formModel.customerShippingAddress = meta.addressDataList.find(x => x.shipTo && x.primaryShipTo)?.address || "";
      this.findCustTop(this.formModel.customerId);
      this.fillOptAddress(meta);
    },
  }
});
