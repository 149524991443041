var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_quotation") } },
    [
      _c(
        "span",
        {
          staticClass: "ant-form-text",
          attrs: { slot: "extra" },
          slot: "extra"
        },
        [
          _vm._v(_vm._s(_vm.formModel.rfqNumber || "-")),
          _c("a-divider", { attrs: { type: "vertical" } }),
          _vm._v(_vm._s(_vm.formModel.status || "-"))
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "formQuotation",
              attrs: { model: _vm.formModel, rules: _vm.formRules }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex" } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_customer_details")) + " ")
                    ])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.customer.name.label),
                            prop: "customerName"
                          }
                        },
                        [
                          _c("CSelectCustomer", {
                            on: {
                              "on-select": function(ref) {
                                var meta = ref.meta

                                return _vm.onchangeCustomerName(meta)
                              }
                            },
                            model: {
                              value: _vm.formModel.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerName", $$v)
                              },
                              expression: "formModel.customerName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.customer.code.label),
                            prop: "customerCode"
                          }
                        },
                        [
                          _c("CSelectCustomerCode", {
                            on: { "on-select": _vm.onSelectCustomerCode },
                            model: {
                              value: _vm.formModel.customerCode,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerCode", $$v)
                              },
                              expression: "formModel.customerCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.customer.contact.label)
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.customer.contact.placeholder
                              ),
                              "allow-clear": "",
                              type: "tel"
                            },
                            model: {
                              value: _vm.formModel.customerContact,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerContact", $$v)
                              },
                              expression: "formModel.customerContact"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formProps.customer.poNumber.label
                            ),
                            prop: "customerPoNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.customer.poNumber.placeholder
                              ),
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.formModel.customerPoNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerPoNumber", $$v)
                              },
                              expression: "formModel.customerPoNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "mb-3", attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formProps.customer.poAttachment.label
                            )
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("CUploadInfo", {
                                    attrs: { "pdf-image": "" }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          !_vm.formModel.status
                            ? _c("CUpload", {
                                attrs: { accept: ".pdf, .png, .jpg, .jpeg" },
                                on: {
                                  success: _vm.onUploadDone,
                                  deleted: _vm.onUploadRemoved
                                }
                              })
                            : [
                                _vm.formModel.customerPoAttachment
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            _vm.poAttachmentLink ||
                                            "javascript:void(0)",
                                          target: "_blank",
                                          rel: "nooppener noreferrer",
                                          download:
                                            _vm.formModel.customerPoAttachment
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formModel.customerPoAttachment
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "file" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_file_not_found"))
                                        )
                                      ],
                                      1
                                    )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex" } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_order_details")) + " ")
                    ])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.order.salesType.label),
                            prop: "salesType"
                          }
                        },
                        [
                          _c("CSelectSalesType", {
                            on: { change: _vm.onChangeSalesType },
                            model: {
                              value: _vm.formModel.salesType,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "salesType", $$v)
                              },
                              expression: "formModel.salesType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.order.date.label),
                            prop: "orderDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.order.date.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            on: { change: _vm.onChangeOrderDate },
                            model: {
                              value: _vm.formModel.orderDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orderDate", $$v)
                              },
                              expression: "formModel.orderDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showExpireDate
                    ? _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formProps.order.expiredDate.label
                                ),
                                prop: "expiredDate"
                              }
                            },
                            [
                              _c("a-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: !_vm.formModel.orderDate,
                                  "disabled-date": _vm.disabledDateExpireDate,
                                  placeholder: _vm.$t(
                                    _vm.formProps.order.expiredDate.placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT
                                },
                                model: {
                                  value: _vm.formModel.expiredDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "expiredDate", $$v)
                                  },
                                  expression: "formModel.expiredDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.order.branch.label),
                            prop: "branch"
                          }
                        },
                        [
                          _c("CSelectBranch", {
                            model: {
                              value: _vm.formModel.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branch", $$v)
                              },
                              expression: "formModel.branch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.order.salesName.label),
                            prop: "salesName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.order.salesName.placeholder
                              ),
                              "allow-clear": ""
                            },
                            model: {
                              value: _vm.formModel.salesName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "salesName", $$v)
                              },
                              expression: "formModel.salesName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.order.note.label)
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              "auto-size": { maxRows: 4 },
                              placeholder: _vm.$t(
                                _vm.formProps.order.note.placeholder
                              )
                            },
                            model: {
                              value: _vm.formModel.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "notes", $$v)
                              },
                              expression: "formModel.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex" } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_delivery_details")) + " ")
                    ])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formProps.delivery.shippingAddress.label
                            ),
                            prop: "customerShippingAddress"
                          }
                        },
                        [
                          _c("CSelectShipAddress", {
                            attrs: {
                              "prop-address": _vm.dtOpt.customerShipToAddress
                            },
                            model: {
                              value: _vm.formModel.customerShippingAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formModel,
                                  "customerShippingAddress",
                                  $$v
                                )
                              },
                              expression: "formModel.customerShippingAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.delivery.etaDate.label),
                            prop: "etaDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.delivery.etaDate.placeholder
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: !_vm.formModel.orderDate,
                              "disabled-date": _vm.disabledDateEtaDate
                            },
                            model: {
                              value: _vm.formModel.etaDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "etaDate", $$v)
                              },
                              expression: "formModel.etaDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_eta_hour"),
                            prop: "etaHour"
                          }
                        },
                        [
                          _c("a-time-picker", {
                            attrs: {
                              placeholder: _vm.$t(
                                _vm.formProps.delivery.etaDate.placeholder
                              ),
                              format: _vm.DEFAULT_TIME_FORMAT,
                              disabled:
                                !_vm.formModel.etaDate ||
                                !_vm.formModel.orderDate
                            },
                            on: { change: _vm.onChangeEtaHour },
                            model: {
                              value: _vm.formModel.etaHour,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "etaHour", $$v)
                              },
                              expression: "formModel.etaHour"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.delivery.freight.label),
                            prop: "freight"
                          }
                        },
                        [
                          _c("CSelectFreight", {
                            model: {
                              value: _vm.formModel.freight,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "freight", $$v)
                              },
                              expression: "formModel.freight"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex" } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _c("p", { staticClass: "text-subtitle-1 mb-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_payment_details")) + " ")
                    ])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formProps.payment.billAddress.label
                            ),
                            prop: "customerBillAddress"
                          }
                        },
                        [
                          _c("CSelectBillAddress", {
                            attrs: {
                              "prop-address": _vm.dtOpt.customerBillAddress
                            },
                            model: {
                              value: _vm.formModel.customerBillAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formModel,
                                  "customerBillAddress",
                                  $$v
                                )
                              },
                              expression: "formModel.customerBillAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.payment.currency.label),
                            prop: "currency"
                          }
                        },
                        [
                          _c("CSelectCurrency", {
                            attrs: {
                              "prop-default": _vm.isModeCreate,
                              disabled: ""
                            },
                            model: {
                              value: _vm.formModel.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "currency", $$v)
                              },
                              expression: "formModel.currency"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProps.payment.top.label),
                            prop: "top"
                          }
                        },
                        [
                          _c("CSelectTermOfPayment", {
                            model: {
                              value: _vm.formModel.top,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "top", $$v)
                              },
                              expression: "formModel.top"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c("p", { staticClass: "text-subtitle-1" }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_product")) + " ")
          ]),
          _c(
            "QuotationTable",
            _vm._g(
              _vm._b({}, "QuotationTable", _vm.compProps, false, true),
              _vm.compEvt
            )
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.formModel.status
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: !_vm.isQuotationSubmitted,
                            loading: _vm.loading.cancel
                          },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "save",
                        loading: _vm.loading.submit,
                        disabled: !_vm.isNew && !_vm.isQuotationSubmitted
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }